import { createBaseTexture, createPixel } from '@/utils/figureUtils';

export function createTextures() {
    if (!this.baseTexture) {
        this.baseTexture = createBaseTexture(this.app, this.pixelSize, 0xadd8e6, 0xf0f0f0);
    }
}

export function createGrid() {
    const rows = Math.ceil(this.app.renderer.height / this.pixelSize);
    const cols = Math.ceil(this.app.renderer.width / this.pixelSize);

    for (let row = 0; row < rows; row++) {
        for (let col = 0; col < cols; col++) {
            createPixel(this.app, this.container, col, row, this.baseTexture, this.pixelSize);
        }
    }
}

export function addGridRows(oldHeight, newHeight) {
    const oldRows = Math.ceil(oldHeight / this.pixelSize);
    const newRows = Math.ceil(newHeight / this.pixelSize);
    const cols = Math.ceil(this.app.renderer.width / this.pixelSize);

    for (let row = oldRows; row < newRows; row++) {
        for (let col = 0; col < cols; col++) {
            createPixel(this.app, this.container, col, row, this.baseTexture, this.pixelSize);
        }
    }
}