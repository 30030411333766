<template>
    <div class="tree-container">
        <pre class="tree-lines">
            <div v-for="(line, index) in treeLines" :key="index" v-html="line" class="fade-in"></div>
        </pre>
    </div>
</template>

<script>
export default {
    name: 'TreeAnimation',
    props: {
        description: {
            type: String,
            required: true,
        },
        technologies: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            treeLines: [],
            branches: ['├── ', '└── ', "│   "],
        };
    },
    mounted() {
        this.buildTree();
    },
    methods: {
        buildTree() {
            const lines = this.description.split('\n')
                .filter(line => line.trim().startsWith('-'))
                .map(line => line.trim().substring(1).trim());
            const formattedLines = lines.map(line => this.formatLine(line));

            this.treeLines = [' ', '│   '];

            formattedLines.forEach((lines, index) => {
                const isLastBranch = index === formattedLines.length - 1;
                lines.forEach((line, i) => {
                    let branch = '';
                    if (i === 0) {
                        branch = isLastBranch ? this.branches[1] : this.branches[0];
                    } else {
                        branch = isLastBranch ? "    " : this.branches[2];
                    }
                    const groupId = `group-${index + 1}`;
                    this.treeLines.push(`${branch}<span class="fade-in" data-group="${groupId}">${line}</span>`);
                });
                if (index < formattedLines.length - 1) {
                    this.treeLines.push("│   ");
                }
            });

            this.$emit('tree-built', this.treeLines.length);
        },
        formatLine(line) {
            const maxLineLength = 100;
            const words = line.split(' ');
            const formattedLine = [];
            let currentLine = '';

            for (const word of words) {
                if (currentLine.length + word.length + 1 <= maxLineLength) {
                    currentLine += (currentLine ? ' ' : '') + word;
                } else {
                    formattedLine.push(currentLine);
                    currentLine = word;
                }
            }

            if (currentLine) {
                formattedLine.push(currentLine);
            }

            return formattedLine;
        },
        highlightGroup(event) {
            const target = event.target;
            if (target.hasAttribute('data-group')) {
                const groupId = target.getAttribute('data-group');
                const groupElements = this.$el.querySelectorAll(`[data-group="${groupId}"]`);
                groupElements.forEach(element => {
                    element.style.fontWeight = 'bold';
                });
            }
        },
        resetHighlight(event) {
            const target = event.target;
            if (!target.hasAttribute('data-group')) {
                const groupElements = this.$el.querySelectorAll('[data-group]');
                groupElements.forEach(element => {
                    element.style.fontWeight = '';
                });
            }
        },
    },
    computed: {
        treeHtml() {
            return this.treeLines.join('<br>');
        },
    },
};
</script>

<style scoped>
:root {
    --pixel-size: 20px;
}

.tree-container {
    margin-top: calc(var(--pixel-size) * -1);
    margin-bottom: calc(var(--pixel-size) * 1);
}

.tree-lines {
    line-height: calc(var(--pixel-size) * 1);
    font-size: calc(var(--pixel-size) * 0.8);
}

.tree-lines span:hover {
    font-weight: bold;
    cursor: default;
}

.fade-in {
    opacity: 0;
    animation: fadeIn 1s forwards;
    animation-delay: 0.2s;
    /* Adjust the delay as needed */
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
</style>
