import { createStore } from 'vuex';

const store = createStore({
    state: {
        mouseX: window.innerWidth / 2,
        mouseY: window.innerHeight / 2,
        time: 0
    },
    mutations: {
        updateMousePosition(state, { mouseX, mouseY }) {
            state.mouseX = mouseX;
            state.mouseY = mouseY;
        },
        incrementTime(state) {
            state.time += 0.001;
        }
    }
});

export default store;
