<template>
    <li class="experience-item border-b border-gray-200">
        <div class="experience-header">
            <div class="title-and-company">
                <span class="clickable-text font-semibold text-lg">{{ experience.title }}, {{ experience.company
                    }}</span>
            </div>
            <div class="dates">
                <time class="text-sm text-gray-600">{{ formatDates(experience.start_date, experience.end_date) }}</time>
            </div>
        </div>
        <TreeAnimation :description="experience.description" :technologies="experience.technologies" />
    </li>
</template>

<script>
import TreeAnimation from './components/TreeAnimation.vue';

export default {
    name: 'ExperienceItem',
    components: {
        TreeAnimation,
    },
    props: {
        experience: {
            type: Object,
            required: true,
        }
    },
    methods: {
        formatDates(startDate, endDate) {
            const formattedStart = this.formatDate(startDate);
            const formattedEnd = endDate ? this.formatDate(endDate) : 'Present';
            return `${formattedStart} - ${formattedEnd}`;
        },
        formatDate(date) {
            if (!date) return 'Present';
            return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
        },
    },
};
</script>

<style scoped>
:root {
    --pixel-size: 20px;
}

.experience-item {
    border-bottom: 1px solid #e5e7eb;
    padding: calc(var(--pixel-size) * 0.5);
    margin-top: calc(var(--pixel-size) * 0.5);
    margin-bottom: calc(var(--pixel-size) * 0.5);
    line-height: calc(var(--pixel-size) * 1);
}

.experience-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: calc(var(--pixel-size) * 1);
    padding-top: 1px;
}

.title-and-company {
    flex: 1;
}

.dates {
    white-space: nowrap;
    padding-top: 1px;
}

.clickable-text {
    cursor: none !important;
    font-size: calc(var(--pixel-size) * 0.8);
}

.clickable {
    cursor: none !important;
}
</style>
