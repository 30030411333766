import { createRouter, createWebHistory } from 'vue-router';
import LoginForm from '@/components/auth/LoginForm.vue';
import BasePage from '@/components/BasePage.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: BasePage,
        meta: { requiresAuth: false },  // Change to false to skip auth check
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginForm,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    // Simulate login for development
    const isAuthenticated = true; // Always true for development

    if (requiresAuth && !isAuthenticated) {
        next('/login');
    } else {
        next();
    }
});

export default router;

