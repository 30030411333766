<template>
    <div ref="pixiContainer" class="pixi-container"></div>
    <div ref="content" class="content"></div>
</template>

<script>
import { initializePixi, updateGridHeight } from './initializationMethods';
import { createTextures, createGrid, addGridRows } from './gridMethods';
import { handleMouseMoveAsync, handleVisibilityChange } from './interactionMethods';
import { handleAchievementsUpdated, embedAllImages, loadImages, createInitialFigures, addFiguresForAchievements, createFigureOfType, onSectionChanged, fetchAchievements, onAchievementsPositions } from './achievementsMethods';
import emitter from '@/utils/eventBus';
import { mapState } from 'vuex';
import { checkPerformance } from './performanceChecker';

export default {
    name: 'PixelGrid',
    props: {
        contentHeight: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            app: null,
            container: null,
            pixelSize: 20,
            maxDistance: 100,
            deformationScale: 0.5,
            additionalRows: 35,
            baseTexture: null,
            figures: {},
            gridCreated: false,
            imageSprites: [],
            imagesEmbedded: false,
            lastMouseMove: 0,
            mouseMoved: false,
            previousPixel: null,
            highlightedPixel: null,
            alteredPixels: new Set(),
            localMouseX: window.innerWidth / 2,
            localMouseY: window.innerHeight / 2,
            syncInterval: null,
            performanceMode: 'full'  // 'full' or 'limited'
        };
    },
    computed: {
        ...mapState(['mouseX', 'mouseY', 'time']),
    },
    watch: {
        contentHeight() {
            this.updateGridHeight();
        }
    },
    mounted() {
        this.initializePixi();
        window.addEventListener('resize', this.resize);
        window.addEventListener('mousemove', this.handleMouseMove);
        window.addEventListener('mouseleave', this.handleMouseLeave);
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        emitter.on('content-resize', this.updateGridHeight);
        emitter.on('section-changed', this.onSectionChanged);
        emitter.on('achievements-updated', this.handleAchievementsUpdated);
        requestAnimationFrame(this.animate);

        // Periodically sync local state with Vuex store
        this.syncInterval = setInterval(() => {
            this.$store.commit('updateMousePosition', {
                mouseX: this.localMouseX,
                mouseY: this.localMouseY
            });
        }, 100); // Sync every 100ms

        // Set performance mode based on the browser and performance test
        this.performanceMode = checkPerformance();
    },
    beforeUnmount() {
        if (this.app) {
            this.imageSprites.forEach(sprite => {
                sprite.destroy({
                    children: true,
                    texture: true,
                    baseTexture: true
                });
            });
            this.app.destroy(true, {
                children: true,
                texture: true,
                baseTexture: true
            });
        }
        window.removeEventListener('resize', this.resize);
        window.removeEventListener('mousemove', this.handleMouseMove);
        window.removeEventListener('mouseleave', this.handleMouseLeave);
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        emitter.off('content-resize', this.updateGridHeight);
        emitter.off('section-changed', this.onSectionChanged);
        emitter.off('achievements-updated', this.handleAchievementsUpdated);

        clearInterval(this.syncInterval); // Clear the sync interval
    },
    methods: {
        initializePixi,
        resize() {
            this.updateGridHeight();
        },
        updateGridHeight,
        createTextures,
        createGrid,
        addGridRows,
        async handleMouseMove(event) {
            this.mouseMoved = true;
            const rect = this.app.view.getBoundingClientRect();
            this.localMouseX = event.clientX - rect.left;
            this.localMouseY = event.clientY - rect.top;

            console.log('Mouse Move:', this.localMouseX, this.localMouseY);

            let color = 0xadd8e6;
            const elementUnderCursor = document.elementFromPoint(event.clientX, event.clientY);
            if (elementUnderCursor && (elementUnderCursor.tagName === 'A' || elementUnderCursor.tagName === 'BUTTON' || elementUnderCursor.classList.contains('clickable'))) {
                color = 0x0000ff;
            }

            const handleMouseMoveData = {
                mouseX: this.localMouseX,
                mouseY: this.localMouseY,
                pixelSize: this.pixelSize,
                previousPixel: this.previousPixel,
                highlightedPixel: this.highlightedPixel,
                appRendererWidth: this.app.renderer.width,
                color
            };

            if (this.performanceMode === 'full') {
                const result = await handleMouseMoveAsync(handleMouseMoveData);

                if (this.previousPixel !== result.highlightedPixel && this.previousPixel !== null && this.container.children[this.previousPixel]) {
                    this.container.children[this.previousPixel].tint = 0xffffff;
                    this.alteredPixels.delete(this.previousPixel);
                }
                this.previousPixel = result.previousPixel;

                if (result.highlightedPixel !== null && this.container.children[result.highlightedPixel]) {
                    this.container.children[result.highlightedPixel].tint = result.color;
                    this.alteredPixels.add(result.highlightedPixel);
                }
                this.highlightedPixel = result.highlightedPixel;
            } else {
                if (this.previousPixel !== null && this.container.children[this.previousPixel]) {
                    this.container.children[this.previousPixel].tint = 0xffffff;
                }
                const col = Math.floor(this.localMouseX / this.pixelSize);
                const row = Math.floor(this.localMouseY / this.pixelSize);
                const pixelIndex = row * Math.ceil(this.app.renderer.width / this.pixelSize) + col;
                if (this.container.children[pixelIndex]) {
                    this.container.children[pixelIndex].tint = color;
                }
                this.previousPixel = pixelIndex;
            }
        },
        handleMouseLeave() {
            console.log('Mouse Leave');
            this.alteredPixels.forEach(pixel => {
                if (this.container.children[pixel]) {
                    this.container.children[pixel].tint = 0xffffff;
                }
            });
            this.alteredPixels.clear();
            this.highlightedPixel = null;
            this.previousPixel = null;
        },
        animate() {
            requestAnimationFrame(this.animate);
            this.$store.commit('incrementTime');

            if (this.mouseMoved) {
                this.mouseMoved = false;
                if (this.performanceMode === 'full') {
                    this.container.children.forEach((sprite) => {
                        const dx = sprite.initialX + this.pixelSize / 2 - this.localMouseX;
                        const dy = sprite.initialY + this.pixelSize / 2 - this.localMouseY;
                        const distance = Math.sqrt(dx * dx + dy * dy);

                        let scale = 1;
                        if (distance < this.maxDistance) {
                            scale = 1 - ((this.maxDistance - distance) / this.maxDistance) * this.deformationScale;
                            const breathingEffect = Math.sin(this.time + sprite.phaseShift) * 0.05;
                            sprite.scale.set(scale + breathingEffect);
                        } else {
                            sprite.scale.set(1);
                        }
                    });
                }
            }
        },
        handleAchievementsUpdated,
        embedAllImages,
        loadImages,
        createInitialFigures,
        addFiguresForAchievements,
        createFigureOfType,
        onSectionChanged,
        fetchAchievements,
        onAchievementsPositions,
        handleVisibilityChange,
    }
};
</script>

<style scoped>
.pixi-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: lightgray;
    will-change: transform;
    backface-visibility: hidden;
}
</style>
