import * as PIXI from 'pixi.js';

export function createImageSprite(app, container, col, row, imagePath, pixelSize, opacity = 1) {
    const texture = PIXI.Texture.from(imagePath);
    const sprite = new PIXI.Sprite(texture);
    sprite.width = pixelSize;
    sprite.height = pixelSize;
    sprite.x = col * pixelSize;
    sprite.y = row * pixelSize;
    sprite.initialX = sprite.x;
    sprite.initialY = sprite.y;
    sprite.alpha = opacity;  // Set the opacity
    sprite.phaseShift = Math.random() * Math.PI * 2;
    container.addChild(sprite);

    return sprite;
}



