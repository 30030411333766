<template>
    <div class="projects-section">
        <section class="font-mono w-full p-0 text-sm leading-6 mx-auto max-w-1xl px-5">
            <div v-for="project in projects" :key="project.id" class="project-item">
                <div class="project-container">
                    <div class="project-title-cell">
                        <h2 class="project-title">{{ project.title }}</h2>
                    </div>
                    <div class="project-dates-container">
                        <div class="project-start-date-cell">
                            <time>{{ formatDate(project.start_date) }}</time>
                        </div>
                        <div class="project-end-date-cell">
                            <time>{{ formatDate(project.end_date) }}</time>
                        </div>
                    </div>
                    <div class="project-technologies-cell">
                        <div class="technology-logos">
                            <div v-for="tech in project.technologies" :key="tech.id" class="technology-logo-wrapper">
                                <img :src="tech.logo" class="technology-logo" />
                                <span class="tooltip">{{ tech.name }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="project-overview-cell">
                        <h3 class="cell-title">Description</h3>
                        <p class="project-overview">{{ project.overview }}</p>
                    </div>
                    <div class="project-impact-cell">
                        <h3 class="cell-title">Impact</h3>
                        <p class="project-impact">{{ project.impact }}</p>
                    </div>
                    <div class="project-url-cell" v-if="project.url">
                        <a :href="project.url" class="project-url">{{ project.url }}</a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { apiClient } from '@/api';

export default {
    name: 'ProjectsSection',
    data() {
        return {
            projects: [],
        };
    },
    methods: {
        async fetchProjects() {
            try {
                const response = await apiClient.get('cv/projects/');
                this.projects = response.data;
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        },
        formatDate(date) {
            if (!date) return 'Present';
            return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
        },
    },
    mounted() {
        this.fetchProjects();
    },
};
</script>

<style scoped>
:root {
    --pixel-size: 20px;
    --border-size: 1.5px;
}

.projects-section {
    margin-top: calc(var(--pixel-size) * 3);
    margin-bottom: calc(var(--pixel-size) * 2);
    z-index: 10;
    line-height: calc(var(--pixel-size) * 1);
    padding-left: calc(var(--pixel-size) * 1);
}

.project-item {
    margin-bottom: calc(var(--pixel-size) * 2.9);
    border: solid black 1.5px;
    padding: calc(var(--pixel-size) * 0.5);
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: auto auto auto 2fr auto;
    gap: calc(var(--pixel-size) * 0.5);
    background-color: transparent;
}

.project-container {
    display: contents;
}

.project-title-cell {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: solid black 1.5px;
    padding: calc(var(--pixel-size) * 0.5);
    border-bottom: solid black 1.5px;
    background-color: transparent;
}

.project-title {
    font-size: calc(var(--pixel-size) * 1.2);
    font-weight: bold;
    background-color: transparent;
}

.project-dates-container {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    display: flex;
    border-top: solid black 1.5px;
    border-right: solid black 1.5px;
    padding: 0;
    background-color: transparent;
}

.project-start-date-cell,
.project-end-date-cell {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(var(--pixel-size) * 0.5);
    border-right: solid black 1.5px;
    border-bottom: solid black 1.5px;
    background-color: transparent;
}

.project-start-date-cell:last-child,
.project-end-date-cell:last-child {
    border-right: none;
}

.project-technologies-cell {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: solid black 1.5px;
    border-right: solid black 1.5px;
    border-bottom: solid black 1.5px;
    padding: calc(var(--pixel-size) * 0.5);
    background-color: transparent;
}

.technology-logos {
    display: flex;
    gap: calc(var(--pixel-size) * 0.5);
}

.technology-logo-wrapper {
    position: relative;
    display: inline-block;
}

.technology-logo {
    width: calc(var(--pixel-size) * 1.5);
    height: auto;
    max-height: 40px;
    /* Set a maximum height to maintain aspect ratio */
    object-fit: contain;
    /* Ensure the image is scaled correctly */
    background-color: transparent;
}

.tooltip {
    visibility: hidden;
    width: auto;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    /* Position above the icon */
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.technology-logo-wrapper:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.project-overview-cell {
    grid-column: 2 / 3;
    grid-row: 1 / 4;
    padding: calc(var(--pixel-size) * 0.5);
    border-top: solid black 1.5px;
    border-bottom: solid black 1.5px;
    border-left: solid black 1.5px;
    background-color: transparent;
    font-size: calc(var(--pixel-size) * 0.8);
}

.project-impact-cell {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
    padding: calc(var(--pixel-size) * 0.5);
    border-top: solid black 1.5px;
    height: calc(var(--pixel-size) * 4);
    background-color: transparent;
    font-size: calc(var(--pixel-size) * 0.8);
}

.project-url-cell {
    grid-column: 1 / 3;
    grid-row: 5 / 6;
    padding: calc(var(--pixel-size) * 0.5);
    border-top: solid black 1.5px;
    background-color: transparent;
}

.cell-title {
    font-weight: bold;
    margin-bottom: calc(var(--pixel-size) * 0.5);
    background-color: transparent;
}

.project-url {
    color: blue;
    text-decoration: underline;
}
</style>
