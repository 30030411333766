<template>
    <div class="achievements-section">
        <section class="font-mono w-full p-0 text-sm leading-6 mx-auto max-w-1xl px-5">
            <div class="grid-container">
                <div v-for="achievement in achievements" :key="achievement.id" class="achievement-item clickable"
                    :ref="'achievementItem' + achievement.id">
                    <div class="achievement-image" :ref="'achievementImage' + achievement.id"></div>
                    <p class="achievement-text">{{ achievement.description }}</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { apiClient } from '@/api';
import emitter from '@/utils/eventBus';

export default {
    name: 'AchievementsSection',
    data() {
        return {
            achievements: [],
        };
    },
    methods: {
        async fetchAchievements() {
            try {
                const response = await apiClient.get('cv/achievements/');
                this.achievements = response.data;
                this.$nextTick(() => {
                    emitter.emit('achievements-updated', this.$el);
                    this.calculateAchievementPositions();
                });
            } catch (error) {
                console.error('Error fetching achievements:', error);
            }
        },
        calculateAchievementPositions() {
            this.$nextTick(() => {
                const items = this.$refs;
                Object.keys(items).forEach(key => {
                    const item = items[key];
                    if (Array.isArray(item)) {
                        item.forEach(el => {
                            if (el && el.classList && el.classList.contains('achievement-item')) {
                                // Adjust positions or other styles here if necessary
                                el.style.transform = 'translateY(0)'; // Reset any transformations
                            }
                        });
                    }
                });
            });
        }
    },
    mounted() {
        this.fetchAchievements();
        window.addEventListener('resize', this.calculateAchievementPositions);
        emitter.on('section-changed', this.calculateAchievementPositions);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.calculateAchievementPositions);
        emitter.off('section-changed', this.calculateAchievementPositions);
    },
};
</script>

<style scoped>
:root {
    --pixel-size: 20px;
}

.achievements-section {
    margin-top: calc(var(--pixel-size) * 2.7);
    margin-bottom: calc(var(--pixel-size) * 2);
    z-index: 10;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: calc(var(--pixel-size) * 2);
    max-width: 2056px;
    margin: 20px;
}

.achievement-item {
    position: relative;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.0);
    border: 0px solid #050505;
    border-radius: 2px;
    padding: 10px;
    margin-bottom: calc(var(--pixel-size) * 1);
    overflow: hidden;
}

.achievement-text {
    position: relative;
    z-index: 1;
    line-height: calc(var(--pixel-size) * 1.5);
    font-size: calc(var(--pixel-size) * 0.8);
    transition: opacity 0.3s ease, background-color 0.3s ease;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0);
    padding: 5px;
    border-radius: 2px;
}

.achievement-image {
    position: relative;
    width: 100%;
    height: calc(var(--pixel-size) * 3);
    z-index: 2;
}

.achievement-item:hover .achievement-image {
    opacity: 0.3;
    z-index: 0;
}

.achievement-item:hover .achievement-text {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
}

@media (max-width: 768px) {
    .grid-container {
        grid-template-columns: 1fr;
    }
}
</style>
