import * as PIXI from 'pixi.js';

export function initializePixi() {
    this.app = new PIXI.Application({
        width: window.innerWidth,
        height: Math.max(window.innerHeight, this.contentHeight + this.additionalRows * this.pixelSize),
        backgroundColor: 0xf0f0f0,
        antialias: true,
    });

    if (this.$refs.pixiContainer) {
        this.$refs.pixiContainer.appendChild(this.app.view);
    } else {
        console.error('pixiContainer reference not found');
        return;
    }

    this.container = new PIXI.Container();
    this.app.stage.addChild(this.container);

    this.highlightedPixel = null;
    this.previousPixel = null;

    this.createTextures();
    this.createGrid();

    // Enable hardware acceleration
    PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;
}

export function updateGridHeight() {
    const newHeight = Math.max(window.innerHeight, this.contentHeight + this.additionalRows * this.pixelSize);
    const oldHeight = this.app.renderer.height;

    if (newHeight !== oldHeight) {
        this.app.renderer.resize(window.innerWidth, newHeight);
        if (newHeight > oldHeight) {
            this.addGridRows(oldHeight, newHeight);
        }
    }
}