// src/mobile-detect.js
function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

document.addEventListener('DOMContentLoaded', function() {
    if (isMobileDevice()) {
        const mobileWarning = document.createElement('div');
        mobileWarning.className = 'mobile-warning';
        mobileWarning.innerHTML = `
            <div class="mobile-warning-content">
                <h1>Mobile Access Not Supported</h1>
                <p>This website is not yet available for mobile phones. Please visit us on a desktop or laptop.</p>
            </div>
        `;
        document.body.appendChild(mobileWarning);
        document.body.classList.add('mobile-detected');
    }
});

