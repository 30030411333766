<template>
    <div class="experience-group mb-2">
        <ul class="experience-list" :ref="`experience-list-${type}`">
            <transition-group name="experience-transition" tag="div">
                <ExperienceItem v-for="experience in experiences" :key="experience.id" :experience="experience" />
            </transition-group>
        </ul>
    </div>
</template>

<script>
import ExperienceItem from './ExperienceItem.vue';

export default {
    name: 'ExperienceGroup',
    components: {
        ExperienceItem,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        experiences: {
            type: Array,
            required: true,
        },
    }
};
</script>

<style scoped>
.experience-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.experience-group {
    position: relative;
    margin-top: calc(var(--pixel-size) * 1);
    margin-left: 0;
    padding-left: 0;
    line-height: calc(var(--pixel-size) * 1);
    font-size: calc(var(--pixel-size) * 0.8);
}

.clickable {
    cursor: none !important;
}
</style>
