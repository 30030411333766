// performanceChecker.js

export function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function runPerformanceTest() {
    const start = performance.now();
    for (let i = 0; i < 1000000; i++) { /* No-op loop */ }
    const end = performance.now();
    return end - start;
}

export function checkPerformance() {
    const performanceThreshold = 100; // Adjust this threshold based on your testing
    if (isSafari() || runPerformanceTest() > performanceThreshold) {
        return 'limited';
    }
    return 'full';
}
