import { createImageSprite } from '@/utils/imageUtils';
import { createStyledPixel, createTriangleStyledPixel, createHexagonStyledPixel, createStarStyledPixel } from '@/utils/figureUtils';
import { apiClient } from '@/api';

export function handleAchievementsUpdated(achievementSection) {
    if (this.imagesEmbedded) {
        return;
    }

    const achievementElements = achievementSection.querySelectorAll('.achievement-item');
    const containerRect = this.$refs.pixiContainer.getBoundingClientRect();
    achievementElements.forEach((element, index) => {
        const achievementImageContainer = element.querySelector('.achievement-image');

        if (achievementImageContainer) {
            const rect = achievementImageContainer.getBoundingClientRect();
            const centerX = rect.left + rect.width / 2 - containerRect.left;
            const centerY = rect.top + rect.height / 2 - containerRect.top;

            const gridX = Math.floor(centerX / this.pixelSize);
            const gridY = Math.floor(centerY / this.pixelSize);
            const multimedia = this.achievements[index].multimedia;
            if (multimedia) {
                console.log(`Embedding images for ${multimedia} centered at (${gridX}, ${gridY})`);
                this.embedAllImages(gridX, gridY, multimedia);
            }
        }
    });

    this.imagesEmbedded = true;
}

export async function embedAllImages(startCol, startRow, image_folder) {
    const baseUrl = `/images_pixel_grid/${image_folder}/`;
    try {
        const response = await fetch(`${baseUrl}manifest.json`);
        if (!response.ok) {
            throw new Error(`Failed to fetch manifest.json: ${response.statusText}`);
        }
        const imageNames = await response.json();
        imageNames.forEach((imageName) => {
            const parts = imageName.split('_');
            const rowOffset = parseInt(parts[parts.length - 2], 10);
            const colOffset = parseInt(parts[parts.length - 1], 10);
            const imagePath = baseUrl + imageName;
            const finalCol = startCol + colOffset;
            const finalRow = startRow + rowOffset;

            const centerCol = Math.floor((finalCol + (finalCol - 5)) / 2);
            const centerRow = Math.floor((finalRow + (finalRow + 1)) / 2);

            const sprite = createImageSprite(this.app, this.container, centerCol, centerRow, imagePath, this.pixelSize, 0.8);
            this.imageSprites.push(sprite);
        });
    } catch (error) {
        console.error('Error loading images:', error);
    }
}

export function loadImages(startCol, startRow, baseUrl, imageNames) {
    console.log(`Loading images starting from grid coordinates (${startCol}, ${startRow})`);
    imageNames.forEach((imageName) => {
        const parts = imageName.split('_');
        const rowOffset = parseInt(parts[parts.length - 2], 10);
        const colOffset = parseInt(parts[parts.length - 1], 10);
        const imagePath = baseUrl + imageName;
        const finalCol = startCol + colOffset;
        const finalRow = startRow + rowOffset;
        console.log(`Creating sprite at (${finalCol}, ${finalRow}) for image ${imagePath}`);

        createImageSprite(this.app, this.container, finalCol, finalRow, imagePath, this.pixelSize);
    });
}

export function createInitialFigures() {
    this.figures = {};
}

export function addFiguresForAchievements(positions) {
    positions.forEach((pos, index) => {
        const gridX = Math.floor(pos.left / this.pixelSize);
        const gridY = Math.floor(pos.top / this.pixelSize);

        const figureTypes = ['redSquare', 'redTriangle', 'redHexagon', 'redStar'];
        const figureType = figureTypes[index % figureTypes.length];
        let figure = this.createFigureOfType(figureType);

        figure.x = gridX * this.pixelSize;
        figure.y = gridY * this.pixelSize;
        this.figures[figureType + index] = figure;

        console.log(`Created ${figureType} figure at (${gridX}, ${gridY}) using position from achievements`);

        if (pos.multimedia) {
            this.embedAllImages(gridX, gridY, pos.multimedia);
        }
    });
}

export function createFigureOfType(type) {
    switch (type) {
        case 'redSquare':
            return createStyledPixel(this.app, this.container, 0, 0, this.pixelSize, 0xff0000, 0xffa500);
        case 'redTriangle':
            return createTriangleStyledPixel(this.app, this.container, 0, 0, this.pixelSize, 0xff0000);
        case 'redHexagon':
            return createHexagonStyledPixel(this.app, this.container, 0, 0, this.pixelSize, 0xff0000);
        case 'redStar':
            return createStarStyledPixel(this.app, this.container, 0, 0, this.pixelSize, 0xff0000);
    }
}

export function onSectionChanged(section) {
    if (section === 'AchievementsSection') {
        this.fetchAchievements();
        this.imageSprites.forEach((sprite) => (sprite.visible = true));
        this.$nextTick(() => {
            const achievementSection = document.querySelector('.achievements-section');
            if (achievementSection) {
                this.handleAchievementsUpdated(achievementSection);
            }
        });
    } else {
        this.imageSprites.forEach((sprite) => (sprite.visible = false));
    }
}

export async function fetchAchievements() {
    try {
        const response = await apiClient.get('cv/achievements/');
        const achievements = response.data;
        this.achievements = achievements;
        console.log('Fetched achievements:', achievements);
        this.addFiguresForAchievements(achievements);
    } catch (error) {
        console.error('Error fetching achievements:', error);
    }
}

export function onAchievementsPositions(positions) {
    positions.forEach((pos) => {
        if (pos.multimedia) {
            console.log(`Embedding images for ${pos.multimedia} starting from (${pos.left}, ${pos.top})`);
            this.embedAllImages(pos.left, pos.top, pos.multimedia);
        }
    });
}
