import { createApp } from 'vue';
import App from './App.vue';
import './assets/tailwind.css';
import './assets/global.css';
import './assets/mobile-warning.css';
import './mobile-detect.js';
import router from './router';

import VueNativeSock from 'vue-native-websocket-vue3';

// Import Font Awesome libraries and icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';

// Import the Vuex store
import store from './store';  // Ensure the path is correct based on where your store.js is located

// Add icons to the library
library.add(faGithub, faLinkedinIn, faEnvelope);

// Create a new Vue instance
const app = createApp(App);

// Register the font-awesome-icon component globally
app.component('font-awesome-icon', FontAwesomeIcon);

// Other Vue plugins
app.use(router);
app.use(VueNativeSock, 'ws://localhost:8000/ws/chat/', {
    format: 'json'
});

// Use the Vuex store
app.use(store);

// Mount the app
app.mount('#app');
