<template>
    <div class="experience-section">
        <section class="font-mono w-full p-0 text-sm leading-6 mx-auto max-w-1xl px-5 clickable">
            <div class="tree">
                <ExperienceGroup v-for="(experiences, type) in groupedExperiences" :key="type" :type="type"
                    :experiences="experiences" />
            </div>
        </section>
    </div>
</template>

<script>
import { apiClient } from '@/api';
import ExperienceGroup from './ExperienceGroup.vue';

export default {
    name: 'ExperienceSection',
    components: {
        ExperienceGroup,
    },
    props: {
        profileId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            experiences: [],
            groupedExperiences: {},
        };
    },
    methods: {
        async fetchExperiences() {
            try {
                const response = await apiClient.get('cv/experiences/');
                this.experiences = response.data;
                this.groupExperiences();
                this.calculateTotalHeight();
            } catch (error) {
                console.error("Error fetching experiences:", error);
            }
        },
        groupExperiences() {
            const grouped = {};
            this.experiences.forEach(exp => {
                if (!grouped[exp.type]) grouped[exp.type] = [];
                grouped[exp.type].push(exp);
            });
            this.groupedExperiences = grouped;
        },
        calculateTotalHeight() {
            const experienceItemHeight = 100; // Approximate height of each ExperienceItem
            const experienceCount = this.experiences.length;
            this.$emit('content-height-calculated', experienceItemHeight * experienceCount);
        }
    }
    ,
    mounted() {
        this.fetchExperiences();
    }
};
</script>

<style scoped>
:root {
    --pixel-size: 20px;
}

.experience-section {
    margin-top: calc(var(--pixel-size) * 2);
    margin-bottom: calc(var(--pixel-size) * 2);
    z-index: 10;
    line-height: calc(var(--pixel-size) * 1);
    padding-left: calc(var(--pixel-size) * 0.5);
}

.tree {
    margin-top: calc(var(--pixel-size) * 1.5);
    margin-bottom: calc(var(--pixel-size) * 1);
    margin-left: 0;
    padding-left: 0;
    position: relative;
    line-height: calc(var(--pixel-size) * 1);
    font-size: calc(var(--pixel-size) * 0.8);
}

.clickable {
    cursor: none !important;
}
</style>
