<template>
    <div class="base-page">
        <PixelGrid ref="pixelGrid" :contentHeight="contentHeight" />
        <div class="content" ref="content">
            <ProfileInfo @profile-loaded="setProfileId" class="clickable" />
            <NavigatorTool @navigate="navigateToSection" class="clickable" />
            <transition name="fade" mode="out-in" @after-enter="onContentResize">
                <keep-alive>
                    <component :is="currentSection" :profile-id="profileId" @navigate="navigateToSection"
                        @content-loaded="updateContentHeight" class="clickable" />
                </keep-alive>
            </transition>
        </div>
    </div>
</template>

<script>
import PixelGrid from './background/PixelGrid.vue';
import ProfileInfo from './profile/ProfileInfo.vue';
import ExperienceSection from './experience/ExperienceSection.vue';
import ProjectsSection from './projects/ProjectsSection.vue';
import AchievementsSection from './achievements/AchievementsSection.vue';
import NavigatorTool from './NavigatorTool.vue';
import emitter from '@/utils/eventBus';

export default {
    name: 'BasePage',
    components: {
        PixelGrid,
        ProfileInfo,
        ExperienceSection,
        ProjectsSection,
        AchievementsSection,
        NavigatorTool,
    },
    data() {
        return {
            profileId: null,
            currentSection: 'ExperienceSection',
            contentHeight: 0,
            additionalRows: 5, // Extra rows to add at the bottom
            pixelSize: 20, // Pixel size defined in your CSS
        };
    },
    methods: {
        setProfileId(profileId) {
            this.profileId = profileId;
        },
        navigateToSection(section) {
            this.currentSection = section;
            console.log('Navigating to section:', section); // Debug log
            emitter.emit('section-changed', section);
            this.$nextTick(() => {
                this.updateContentHeight();
                emitter.emit('content-resize');
            });
        },
        onContentResize() {
            this.$nextTick(() => {
                this.updateContentHeight();
                emitter.emit('content-resize');
            });
        },
        updateContentHeight() {
            this.contentHeight = this.$refs.content.offsetHeight;
            this.updatePixelGridHeight();
        },
        updatePixelGridHeight() {
            const totalHeight = this.contentHeight + this.additionalRows * this.pixelSize;
            this.$refs.pixelGrid.updateGridHeight(totalHeight);
        }
    },
    mounted() {
        this.updateContentHeight();
        window.addEventListener('resize', this.updateContentHeight);
        emitter.on('content-height-calculated', this.updateContentHeight);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateContentHeight);
        emitter.off('content-height-calculated', this.updateContentHeight);
    }
};
</script>

<style scoped>
:root {
    --pixel-size: 20px;
}

.base-page {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1;
    position: relative;
    z-index: 10;
    padding: calc(var(--pixel-size) * 2);
    background: transparent;
    line-height: calc(var(--pixel-size) * 1);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
