<template>
    <div class="profile-info-container">
        <div class="profile-info mx-auto font-mono text-base leading-snug max-w-1xl px-5">
            <div class="profile-header flex items-center mb-5" v-if="profile">
                <!-- Profile name, email, and about me -->
                <div class="profile-header-content flex items-start w-full flex-col">
                    <div class="profile-text flex-grow">
                        <h2 class="profile-name">
                            {{ profile.full_name }}&mdash;<br>
                            <span class="about-me">
                                Experienced developer<br> looking for the next challenge!
                            </span>
                        </h2>
                    </div>
                    <div class="profile-email mt-2">
                        <a :href="'mailto:' + profile.contact_info.email" class="clickable">{{
                profile.contact_info.email }}</a>
                    </div>
                </div>
            </div>
            <div class="profile-details" v-if="profile">
                <!-- Contact Information -->
                <div class="profile-section mb-7" v-if="profile.contact_info && profile.contact_info.phone">
                    <h3 class="subsection-heading">Contact Information</h3>
                    <div class="info-content">
                        <div class="info-row">
                            <strong class="info-label">Phone:</strong>
                            <span class="clickable">{{ profile.contact_info.phone }}</span>
                        </div>
                    </div>
                </div>
                <!-- Skills -->
                <div class="profile-section mb-7" v-if="profile.skills && profile.skills.length">
                    <h3 class="subsection-heading">Skills</h3>
                    <ul class="skills-list info-content">
                        <li v-for="skill in profile.skills" :key="skill.id" class="clickable">{{ skill.name }}</li>
                    </ul>
                </div>
                <!-- Interests -->
                <div class="profile-section" v-if="profile.interests">
                    <h3 class="subsection-heading">Interests</h3>
                    <p class="info-content">{{ profile.interests }}</p>
                </div>
                <!-- Bio -->
                <div class="profile-section" v-if="profile.bio">
                    <h3 class="subsection-heading">Bio</h3>
                    <p class="info-content">{{ profile.bio }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { apiClient } from '@/api';

export default {
    name: 'ProfileInfo',
    data() {
        return {
            profile: null,
        };
    },
    async created() {
        try {
            const response = await apiClient.get('account/profile/');
            this.profile = response.data;
            this.profile.about_me = "A dedicated Python developer - looking for the next challenge!";
            this.$emit('profile-loaded', this.profile.id);
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    },
};
</script>

<style scoped>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.profile-info-container {
    position: relative;
    z-index: 10;
    /* Ensure it is above the PixelGrid */
    pointer-events: none;
    /* Allow events to pass through */
}

.profile-info {
    position: relative;
    z-index: 10;
    /* Ensure it is above the PixelGrid */
    background: transparent;
    padding: calc(var(--pixel-size) * 2);
    pointer-events: auto;
    /* Enable events only on the text elements */
}

.profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-header-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.profile-text {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.profile-name {
    font-size: 2rem;
    padding-left: 1.5px;
    padding-top: 5px;
    font-weight: bold;
    color: black;
    pointer-events: auto;
    /* Ensure clickability */
}

.about-me {
    font-size: 2rem;
    color: black;
    font-family: 'Source Code Pro', monospace;
    font-style: normal;
}

.profile-email {
    font-size: 1.1rem;
    color: black;
    text-decoration: none;
    pointer-events: auto;
    padding-top: 8px;
    padding-left: 1.5px;
    /* Ensure clickability */
}

.profile-email a:hover {
    text-decoration: underline;
    /* Optional: underline on hover for better UX */
}

.subsection-heading {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
}

.info-content {
    margin-left: 1rem;
}

.info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.info-label {
    font-weight: bold;
    white-space: nowrap;
    color: white;
}

.skills-list {
    list-style-type: none;
    padding: 0;
}

.skills-list li {
    margin-bottom: 0.5rem;
    color: white;
}

.profile-section p,
.profile-section span {
    color: white;
}

.clickable {
    cursor: pointer;
}
</style>
