<template>
    <div class="navigator">
        <h2 class="navigator-title clickable" :class="{ active: activeSection === 'ExperienceSection' }"
            @click="navigate('ExperienceSection')" ref="experienceTitle">
            Experience
        </h2>
        <h2 class="navigator-title clickable" :class="{ active: activeSection === 'ProjectsSection' }"
            @click="navigate('ProjectsSection')" ref="projectsTitle">
            Projects
        </h2>
        <h2 class="navigator-title clickable" :class="{ active: activeSection === 'AchievementsSection' }"
            @click="navigate('AchievementsSection')" ref="achievementsTitle">
            Hobbies & Achievements
        </h2>
        <div class="underline" :style="underlineStyle"></div>
    </div>
</template>

<script>
export default {
    name: 'NavigatorTool',
    data() {
        return {
            activeSection: 'ExperienceSection',
            underlineWidth: 0,
            underlineLeft: 0,
        };
    },
    computed: {
        underlineStyle() {
            return {
                width: `${this.underlineWidth}px`,
                left: `${this.underlineLeft}px`,
            };
        },
    },
    methods: {
        navigate(section) {
            this.activeSection = section;
            this.updateUnderline();
            this.$emit('navigate', section);
            this.$nextTick(() => {
                this.$emit('content-resize');
            });
        },
        updateUnderline() {
            this.$nextTick(() => {
                let activeTitle = this.activeSection === 'ExperienceSection' ? this.$refs.experienceTitle : this.$refs.projectsTitle;
                if (this.activeSection === 'AchievementsSection') {
                    activeTitle = this.$refs.achievementsTitle;
                }
                this.underlineWidth = activeTitle.offsetWidth - (parseInt(window.getComputedStyle(activeTitle).paddingLeft) + parseInt(window.getComputedStyle(activeTitle).paddingRight));
                this.underlineLeft = activeTitle.offsetLeft + (parseInt(window.getComputedStyle(activeTitle).paddingLeft));
            });
        },
    },
    mounted() {
        this.updateUnderline();
        window.addEventListener('resize', this.updateUnderline);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateUnderline);
    },
};
</script>

<style scoped>
:root {
    --pixel-size: 20px;
}

.navigator {
    display: flex;
    justify-content: flex-start;
    margin: calc(var(--pixel-size) * 2) 0;
    position: relative;
    width: 100%;
    margin-top: 10px;
}

.navigator-title {
    padding: 0 calc(var(--pixel-size) * 2);
    margin-right: calc(var(--pixel-size) * -1);
    font-size: calc(var(--pixel-size) * 1);
    cursor: pointer;
    position: relative;
    text-align: left;
    line-height: calc(var(--pixel-size) * 1);
    height: calc(var(--pixel-size) * 0.5);
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.underline {
    position: absolute;
    bottom: -2px;
    height: 2px;
    background-color: rgb(247, 1, 1);
    transition: left 0.3s ease, width 0.3s ease;
}
</style>
