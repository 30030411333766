import * as PIXI from 'pixi.js';

export function createBaseTexture(app, pixelSize, borderColor, baseColor) {
    const graphic = new PIXI.Graphics();
    graphic.lineStyle(1, borderColor, 1);
    graphic.beginFill(baseColor, 1);
    graphic.drawRect(0, 0, pixelSize, pixelSize);
    graphic.endFill();
    return app.renderer.generateTexture(graphic);
}

export function createStyledSquareTexture(app, pixelSize, borderColor, fillColor) {
    const graphic = new PIXI.Graphics();
    graphic.lineStyle(1, 0xadd8e6, 1);
    graphic.beginFill(0xf0f0f0, 1);
    graphic.drawRect(0, 0, pixelSize, pixelSize);
    graphic.endFill();

    const innerSquareSize = pixelSize / 2;
    graphic.lineStyle(1, borderColor, 1);
    graphic.beginFill(fillColor, 1);
    graphic.drawRect(
        (pixelSize - innerSquareSize) / 2,
        (pixelSize - innerSquareSize) / 2,
        innerSquareSize,
        innerSquareSize
    );
    graphic.endFill();

    return app.renderer.generateTexture(graphic);
}

export function createStyledTriangleTexture(app, pixelSize, fillColor) {
    const graphic = new PIXI.Graphics();
    graphic.lineStyle(1, 0xadd8e6, 1);
    graphic.beginFill(0xf0f0f0, 1);
    graphic.drawRect(0, 0, pixelSize, pixelSize);
    graphic.endFill();

    graphic.lineStyle(1, fillColor, 1);
    graphic.beginFill(fillColor, 1);
    graphic.moveTo(pixelSize / 2, pixelSize / 4);
    graphic.lineTo((pixelSize / 4) * 3, (pixelSize / 4) * 3);
    graphic.lineTo(pixelSize / 4, (pixelSize / 4) * 3);
    graphic.closePath();
    graphic.endFill();

    return app.renderer.generateTexture(graphic);
}

export function createStyledCircleTexture(app, pixelSize, fillColor) {
    const graphic = new PIXI.Graphics();
    graphic.lineStyle(1, 0xadd8e6, 1);
    graphic.beginFill(0xf0f0f0, 1);
    graphic.drawRect(0, 0, pixelSize, pixelSize);
    graphic.endFill();

    graphic.lineStyle(1, fillColor, 1);
    graphic.beginFill(fillColor, 1);
    graphic.drawCircle(pixelSize / 2, pixelSize / 2, pixelSize / 4);
    graphic.endFill();

    return app.renderer.generateTexture(graphic);
}

export function createSquareWithCircleTexture(app, pixelSize, borderColor, squareFillColor, circleFillColor) {
    const graphic = new PIXI.Graphics();
    graphic.lineStyle(1, borderColor, 1);
    graphic.beginFill(squareFillColor, 1);
    graphic.drawRect(0, 0, pixelSize, pixelSize);
    graphic.endFill();

    graphic.lineStyle(1, circleFillColor, 1);
    graphic.beginFill(circleFillColor, 1);
    graphic.drawCircle(pixelSize / 2, pixelSize / 2, pixelSize / 4);
    graphic.endFill();

    return app.renderer.generateTexture(graphic);
}

export function createStyledHexagonTexture(app, pixelSize, fillColor) {
    const graphic = new PIXI.Graphics();
    graphic.lineStyle(1, 0xadd8e6, 1);
    graphic.beginFill(0xf0f0f0, 1);
    graphic.drawRect(0, 0, pixelSize, pixelSize);
    graphic.endFill();

    const radius = pixelSize / 4;
    const centerX = pixelSize / 2;
    const centerY = pixelSize / 2;

    graphic.lineStyle(1, fillColor, 1);
    graphic.beginFill(fillColor, 1);
    graphic.moveTo(centerX + radius * Math.cos(0), centerY + radius * Math.sin(0));
    for (let i = 1; i <= 6; i++) {
        graphic.lineTo(centerX + radius * Math.cos((i * 2 * Math.PI) / 6), centerY + radius * Math.sin((i * 2 * Math.PI) / 6));
    }
    graphic.endFill();

    return app.renderer.generateTexture(graphic);
}

export function createStyledStarTexture(app, pixelSize, fillColor) {
    const graphic = new PIXI.Graphics();
    graphic.lineStyle(1, 0xadd8e6, 1);
    graphic.beginFill(0xf0f0f0, 1);
    graphic.drawRect(0, 0, pixelSize, pixelSize);
    graphic.endFill();

    const centerX = pixelSize / 2;
    const centerY = pixelSize / 2;
    const outerRadius = pixelSize / 4;
    const innerRadius = outerRadius / 2;
    const spikes = 5;

    graphic.lineStyle(1, fillColor, 1);
    graphic.beginFill(fillColor, 1);
    graphic.moveTo(centerX, centerY - outerRadius);
    for (let i = 0; i < spikes; i++) {
        graphic.lineTo(centerX + outerRadius * Math.cos((i * 2 * Math.PI) / spikes - Math.PI / 2), centerY + outerRadius * Math.sin((i * 2 * Math.PI) / spikes - Math.PI / 2));
        graphic.lineTo(centerX + innerRadius * Math.cos((i * 2 * Math.PI) / spikes - Math.PI / 2 + Math.PI / spikes), centerY + innerRadius * Math.sin((i * 2 * Math.PI) / spikes - Math.PI / 2 + Math.PI / spikes));
    }
    graphic.closePath();
    graphic.endFill();

    return app.renderer.generateTexture(graphic);
}

export function createPixel(app, container, col, row, texture, pixelSize) {
    const sprite = new PIXI.Sprite(texture);
    sprite.x = col * pixelSize;
    sprite.y = row * pixelSize;
    sprite.initialX = sprite.x;
    sprite.initialY = sprite.y;
    sprite.phaseShift = Math.random() * Math.PI * 2;

    container.addChild(sprite);
    // console.log(`Created pixel at (${col}, ${row})`); // Debug log
    return sprite; // Return the created PIXI object
}

export function createStyledPixel(app, container, col, row, pixelSize, borderColor, fillColor, circleFillColor) {
    const texture = createSquareWithCircleTexture(app, pixelSize, borderColor, fillColor, circleFillColor);
    return createPixel(app, container, col, row, texture, pixelSize);
}

export function createTriangleStyledPixel(app, container, col, row, pixelSize, fillColor) {
    const texture = createStyledTriangleTexture(app, pixelSize, fillColor);
    return createPixel(app, container, col, row, texture, pixelSize);
}

export function createHexagonStyledPixel(app, container, col, row, pixelSize, fillColor) {
    const texture = createStyledHexagonTexture(app, pixelSize, fillColor);
    return createPixel(app, container, col, row, texture, pixelSize);
}

export function createStarStyledPixel(app, container, col, row, pixelSize, fillColor) {
    const texture = createStyledStarTexture(app, pixelSize, fillColor);
    return createPixel(app, container, col, row, texture, pixelSize);
}
