import greenlet from 'greenlet';

export const handleMouseMoveAsync = greenlet(async function (data) {
    const { mouseX, mouseY, pixelSize, previousPixel, highlightedPixel, appRendererWidth, color } = data;

    const col = Math.floor(mouseX / pixelSize);
    const row = Math.floor(mouseY / pixelSize);

    const pixelIndex = row * Math.ceil(appRendererWidth / pixelSize) + col;

    console.log('Mouse Move Async:', pixelIndex, color);

    if (highlightedPixel !== pixelIndex) {
        return {
            previousPixel: highlightedPixel,
            highlightedPixel: pixelIndex,
            color
        };
    }

    return {
        previousPixel,
        highlightedPixel,
        color
    };
});

export function animate() {
    requestAnimationFrame(this.animate);
    this.$store.commit('incrementTime');

    this.container.children.forEach((sprite) => {
        const dx = sprite.initialX + this.pixelSize / 2 - this.mouseX;
        const dy = sprite.initialY + this.pixelSize / 2 - this.mouseY;
        const distance = Math.sqrt(dx * dx + dy * dy);

        let scale = 1;
        if (distance < this.maxDistance) {
            scale = 1 - ((this.maxDistance - distance) / this.maxDistance) * this.deformationScale;
            const breathingEffect = Math.sin(this.time + sprite.phaseShift) * 0.05;
            sprite.scale.set(scale + breathingEffect);
        } else {
            sprite.scale.set(1);
        }
    });
}

export function handleVisibilityChange() {
    if (document.visibilityState === 'visible') {
        this.app.renderer.render(this.app.stage);
    }
}
