// /frontend/src/api/index.js

import axios from 'axios';

const isDebug = process.env.VUE_APP_DEBUG === 'true';
const baseURL = isDebug ? process.env.VUE_APP_API_BASE_URL_DEV : process.env.VUE_APP_API_BASE_URL_PROD;

const apiClient = axios.create({
    baseURL: baseURL,
});

apiClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['X-Frontend-Token'] = 'postgres';
    return config;
});

export { apiClient };
