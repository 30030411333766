<!-- src/components/auth/LoginForm.vue -->
<template>
    <div class="login-form">
        <h2>Login</h2>
        <form @submit.prevent="login">
            <div>
                <label for="username">Username:</label>
                <input type="text" id="username" v-model="username" required>
            </div>
            <div>
                <label for="password">Password:</label>
                <input type="password" id="password" v-model="password" required>
            </div>
            <button type="submit">Login</button>
        </form>
    </div>
</template>

<script>
import { apiClient } from '@/api';

export default {
    name: 'LoginForm',
    data() {
        return {
            username: '',
            password: '',
        };
    },
    methods: {
        async login() {
            try {
                const response = await apiClient.post('token/', {
                    username: this.username,
                    password: this.password,
                });
                const { access, refresh } = response.data;
                localStorage.setItem('access_token', access);
                localStorage.setItem('refresh_token', refresh);
                this.$router.push('/'); // Navigate to the home route after successful login
            } catch (error) {
                console.error('Login failed:', error);
                // Display an error message to the user
            }
        },
    },
};
</script>

<style scoped>
/* Add your styles for the login component */
</style>